<template>
  <component
    :is="wrapper"
    v-tooltip="tooltipDefinition"
    class="relative grid place-content-center w-[--width] outline-none h-[--height] shrink-0 before:absolute"
    :class="{
      'opacity-50 cursor-not-allowed': disabled,
      'before:scale-90 hover:before:scale-100 focus-visible:before:ring-2 before:ring-gray-100  before:content-[\'\'] before:transition before:-inset-2 shrink-0 before:absolute':
        !disabled,
      'text-gray-500': color === undefined,
      'text-primary/70': color === 'primary',
      'text-error/80': color === 'error',
      'hover:before:bg-gray-50 hover:text-black':
        !disabled && color === undefined,
      'hover:before:bg-primary-200 hover:text-primary':
        !disabled && color === 'primary',
      'hover:before:bg-error-50 hover:text-error':
        !disabled && color === 'error',
      'before:rounded-full': !tile,
      'before:rounded': tile,
    }"
    :disabled="disabled"
    :style="{
      '--width': `${iconSize}px`,
      '--height': `${iconSize}px`,
    }"
    :to="to"
    type="button"
  >
    <app-icon class="relative" :icon="icon" :size="iconSize ?? 20" />
    <span class="sr-only">{{ label }}</span>
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { toKeyboardShortcutLabel } from "@pollen/core/client/composables/keyboard-shortcut.hook";
import type { TranslateResult } from "vue-i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { RouteLocationRaw } from "#vue-router";

const properties = withDefaults(
  defineProps<{
    icon: string;
    label: TranslateResult;
    to?: RouteLocationRaw;
    iconSize?: number;
    tag?: keyof HTMLElementTagNameMap;
    disabled?: boolean;
    color?: "primary" | "error";
    tile?: boolean;
    tooltip?: boolean | { value?: string; placement?: string };
    keyboardShortcut?: string[];
  }>(),
  {
    to: undefined,
    iconSize: 20,
    tag: undefined,
    color: undefined,
    tooltip: undefined,
    keyboardShortcut: undefined,
  },
);

const wrapper = computed(
  () =>
    properties.tag ??
    (properties.to ? resolveComponent("nuxt-link") : "button"),
);

const attributes = useAttrs();

if (properties.keyboardShortcut && attributes.onClick) {
  useKeyboardShortcut(properties.keyboardShortcut, () => {
    if (properties.disabled) return;

    toArray(attributes.onClick).forEach((handler) => (handler as () => void)());
  });
}

const tooltipDefinition = computed(() => {
  if (properties.disabled) return;

  if (properties.keyboardShortcut) {
    return {
      html: true,
      value: `${properties.label} <span class="ml-1 bg-primary-900 px-[2px] rounded-sm border-primary-700 border font-mono">${toKeyboardShortcutLabel(properties.keyboardShortcut)}</span>`,
      placement:
        typeof properties.tooltip === "object"
          ? properties.tooltip.placement
          : undefined,
    };
  }

  return typeof properties.tooltip === "boolean"
    ? properties.label
    : properties.tooltip;
});
</script>
