export function useKeyboardShortcut(
  keys: string | string[],
  handler: () => unknown,
) {
  const label = toArray(keys).join("+");
  const isShortcutUsed = useMagicKeys()[label];

  if (isShortcutUsed) {
    watch(isShortcutUsed, (isUsed) => {
      if (!isUsed) return;

      handler();
    });
  }

  return { label };
}

export function toKeyboardShortcutLabel(keys: string[]) {
  return keys
    .map((key) => {
      if (key === "meta") return "⌘";
      if (key === "shift") return "⇧";

      return key;
    })
    .join("+");
}
